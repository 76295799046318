<template>
  <!-- qrcode START -->
  <div class="account-qrcode">
    <!-- qr-code:code START -->
    <div class="code-holder">
      <div class="code">
        <img v-if="iconCode" :src="iconCode" />
        <span
          class="f-like-image-loading-middle"
          v-if="!iconCode"
        ></span>
      </div>
    </div>
    <!-- qr-code:code END -->

    <!-- form:qrcode START -->
    <el-form
      status-icon
      :rules="rules"
      ref="qrcodeForm"
      :model="qrcodeForm"
      class="login-form"
      :statusIcon="false"
      @submit.prevent.native="submitForm('qrcodeForm')"
    >
      <!-- form:qrcode:password:old-password START -->
      <el-form-item prop="currentPassword">
        <p
          v-html="$t('account.qrcode.headline')"
        ></p>
        <el-input
          :type="type"
          :placeholder="`${$t('login.currentpassword')} *`"
          v-model="qrcodeForm.currentPassword"
          @keyup.enter.native="submitForm('qrcodeForm')"
          autocomplete="off"
        >
          <i
            slot="suffix"
            class="el-icon-view"
            v-on:click="showHidePassword()"
          ></i>
        </el-input>
      </el-form-item>
      <!-- form:qrcode:password:old-password END -->

      <!-- form:qrcode:submit START -->
      <el-form-item>
        <el-button
          class="btn-default"
          type="primary"
          v-on:click="submitForm('qrcodeForm')"
        >{{ $t('login.save') }}</el-button>

      </el-form-item>
      <!-- form:qrcode:submit END -->

    </el-form>
    <!-- form:qrcode END -->

    <!-- form:qrcode:loading START -->
    <loading-default
      :loadingVisible="loadingVisible"
      :headline="loadingHeadline"
      :excerpt="loadingExcerpt"
    />
    <!-- form:qrcode:loading END -->
  </div>
  <!-- qrcode END -->
</template>

<script>
import titleMixin from '@/mixins/titleMixin';

export default {
  name: 'qrcode',
  mixins: [titleMixin],
  components: {
    LoadingDefault: () => import('../../components/shared/loading/Default.vue'),
  },
  data() {
    return {
      qrcode: '',
      /**
       * Title (Page) - If "pageTitleFrom" exists - show simple Navigation...
       */
      pageTitleFromComponent: this.$t('account.qrcode.headline'),

      /**
       * Loading
       */
      loadingVisible: false,
      loadingHeadline: this.$t('loading.update.headline'),
      loadingExcerpt: this.$t('loading.update.excerpt'),

      /**
       * Form (Variables)
       */
      serverErrorMessages: [],
      globalErrorMessage: false,
      type: 'password',

      /**
       * Form (Models) + Pass data to mixin
       */
      qrcodeForm: {
        currentPassword: '',
      },
      /**
       * Form (Rules - Validation)
       */
      rules: {
        currentPassword: [
          {
            required: true,
            message: this.$t('login.errorformfield'),
            trigger: [
              'blur',
              'change',
            ],
          },
          {
            validator: this.validatePassword,
            trigger: [
              'blur',
              'change',
            ],
          },
        ],
      },
    };
  },
  async created() {
    this.qrcode = await this.FETCH_QRCODE();
  },
  computed: {
    iconCode() {
      return `${this.qrcode}`;
    },
  },
  methods: {
    async FETCH_QRCODE() {
      await this.$store.dispatch('setQrcode');
      return this.$store.getters.qrcode;
    },

    submitForm(formName) {
      const formData = new FormData();
      if (formName === 'qrcodeForm') {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.loadingVisible = true;
            /**
             * Locale
             */
            formData.append('locale', localStorage.getItem('lang'));
            /**
             * Data
             */
            formData.append('password', this.qrcodeForm.currentPassword);

            // Display the key/value pairs
            /* [...formData.entries()].forEach((row) => {
              console.log(`${row}`);
            }); */

            // Update user data
            this.$http.post(`${process.env.VUE_APP_BASE_URL}user/qr`, formData, {
              headers: {
                'X-Api-Key': `${process.env.VUE_APP_X_API_KEY}`,
                Authorization: `Bearer ${localStorage.getItem('auth')}`,
              },
            })
              .then((response) => {
                if (response.data) {
                  // Close loading
                  this.loadingVisible = false;

                  // notification - error
                  this.success();
                } else {
                  this.globalErrorMessage = true;
                  this.resetForm.agbs = false;

                  console.log('error register submit!!');
                }
              })
              .catch((error) => {
                if (!error.response) {
                  // network error
                  this.errorStatus = 'Error: Network Error';
                } else {
                  this.errorStatus = error.response.data;
                  this.loadingVisible = false;
                  this.serverErrorMessages = this.errorStatus.errors;

                  this.serverErrorMessages.forEach((message) => {
                    this.error(`${this.$t(`error.${message.code}`)}`);
                  });
                }
              });
          }
        });
      }
    },

    resetForm(formName) {
      if (this.$refs[formName]) {
        this.globalErrorMessage = false;
        this.$refs[formName].resetFields();
        this.type = 'password';
      }
    },

    showHidePassword() {
      if (this.type === 'password') {
        this.type = 'text';
      } else {
        this.type = 'password';
      }
    },

    success() {
      this.$notify({
        message: this.$t('notification.success'),
        type: 'success',
        position: 'bottom-right',
      });

      // clean up input fields
      setTimeout(() => {
        this.resetForm('qrcodeForm');
      }, 750);
    },

    error(message) {
      this.$notify({
        message: this.$t(`${message}`),
        type: 'error',
        position: 'bottom-right',
      });
    },
  },
};
</script>
